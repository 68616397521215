export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    // Container
    '.container': {
      backgroundColor: 'background',
      padding: ['', '', '', '0rem 1rem'],
      display: 'flex',
      justifyContent: 'center',
      '.smallNavMenu': {
        padding: '0.5rem',
        li: {
          a: {
            color: 'text'
          }
        },
        display: ['none', 'none', 'none', 'none', 'flex', 'flex'],
        '.react-reveal': {
          display: 'flex',
          flexDirection: 'row'
        },
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          a: {
            color: 'text',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        }
      }
    },
    '.order-now': {
      a: {
        padding: '0.5rem 1rem',
        color: 'white !important',
        backgroundColor: 'primary',
        borderRadius: '50px',
        fontWeight: 'bold'
      }
    },

    '.containerScrolled': {
      backgroundColor: 'background',

      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none',
      '.smallNavMenu': {
        borderColor: 'black',
        'li.navItem': {
          a: {
            color: 'text'
          }
        }
      }
    },

    // hamburger
    '.hamburger': {
      borderRadius: '10px',
      background: 'none',
      border: 'none',
      padding: '0.75rem',
      '> div': {
        backgroundColor: 'primary',
        height: '2px'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    'li.navItem': {
      a: {
        color: 'text',
        fontFamily: 'body',
        // fontSize: ['1.7rem', '1.7rem', '1.7rem', '1.7rem', '1.7rem'],
        fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '1rem'],
        fontWeight: 'bold',
        letterSpacing: '0px',
        textTransform: 'capitalize'
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '80px'
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: '60px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: ['80px', '100px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'none'
      },
      '.order-now a': {
        color: 'white'
      }
    }
  },

  footer: {
    position: ['', '', 'fixed'],
    bottom: '0rem',
    zIndex: '977',
    width: '100%',
    backgroundColor: 'black',
    '.logo, .image': {
      // filter: 'brightness(0) invert(1)',
      display: 'none'
    },
    '.contactDetails-container': {
      display: 'none'
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['0.5rem 0.5rem 3.5rem', '', '0rem 3rem 0rem'],
    '.multiButtonContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: '0rem',
      padding: '0.5rem 0.5rem 0.5rem 0rem',
      a: {
        color: 'white',
        fontSize: '0.9rem',
        margin: '0rem',
        padding: '0.5rem 0.5rem 0.5rem 0rem'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      padding: '0.25rem 1rem 0.25rem 0rem',
      maxWidth: '175px'
    },

    '.socialIconsContainer': {
      padding: '0.25rem 1rem 0.25rem 0rem',
      a: {
        color: 'white',
        margin: '0.25rem'
      }
    },
    '.copyright': {
      color: 'white',
      padding: '0.25rem 1rem 0.25rem 0rem',
      margin: '0rem',
      fontSize: '0.75rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['3rem', '3.5rem', '4rem', '4.5rem', '5.5rem'],
    fontWeight: 'normal',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    color: 'primary',
    fontStyle: 'italic',
    fontFamily: 'heading'
  },
  subtitle: {
    fontFamily: 'subheading',
    textAlign: 'inherit',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2.25rem'],
    marginBottom: '1.5rem',
    color: 'secondary',
    border: 'none',
    fontWeight: 'normal',
    color: 'secondary',
    letterSpacing: '1px'
  },
  text: {
    fontFamily: 'body',
    lineHeight: '1.75',
    fontSize: ['1.2rem', '', '1.5rem'],

    '*': {
      lineHeight: '1.75',
      textAlign: 'inherit',
      fontSize: 'inherit',
      color: 'inherit'
    }
  },

  sideBySide1: {
    overflow: 'hidden',
    minHeight: '90vh',
    '.content': {},
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },

    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'flex-start',
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  centerBlock: {
    variant: 'customVariants.sideBySide1',

    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center',
      color: 'white',
      fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      fontFamily: 'display',
      color: 'background',
      mb: '0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      color: 'white',
      '*': {
        color: 'white'
      }
    },
    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['3rem 0rem 0rem', '', '60px 0rem 0rem'],
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      height: ['calc(100vh - 85px) !important', '', '', 'calc(100vh - 105px) !important'],
      width: '100%'
    },
    'svg.slick-arrow': {
      height: '30px',
      width: '30px',
      color: 'white',
      padding: '0.1rem',
      bottom: '0rem',
      top: 'unset'
    },
    'svg.slick-prev': {
      left: '1rem'
    },
    'svg.slick-next': {
      left: '3rem',
      right: 'unset'
    },

    '.hero_content_container': {
      position: 'absolute',
      top: 'calc(50% + 45px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      textAlign: 'center',
      alignItems: 'center',
      margin: '0rem',
      maxWidth: 'unset'
    },

    '.title': {
      variant: 'customVariants.title',
      fontWeight: 'bold',
      fontFamily: 'display',
      textAlign: 'center',
      marginBottom: '0rem',
      color: 'light',
      textShadow: '1px 1px 35px #1e1e1e'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.75rem', '2rem', '2.5rem', '3rem'],
      fontFamily: 'body',
      color: 'light',
      fontWeight: '200',
      marginBottom: '0rem',
      textAlign: 'right',
      width: '90%',
      textTransform: 'lowercase',
      textShadow: '1px 1px 35px #1e1e1e'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      color: 'light',
      textShadow: '1px 1px 35px #1e1e1e'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none !important'
  },

  homepageShout: {
    backgroundColor: '#fff',
    padding: ['1rem', '', '', '2rem'],
    '.title': {
      fontFamily: 'body',
      m: '0rem 0.5rem'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      backgroundColor: ['#85493d', '', '', 'primary'],
      color: ['white', '', '', 'white'],
      m: '0rem 0.5rem'
    },
    '.date': {
      backgroundColor: 'secondary',
      color: 'text',
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      order: ['', '', '4'],
      cursor: 'pointer'
    },
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },
  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },
  homepageOrder: {
    variant: 'customVariants.centerBlock'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock'
  },

  homepageQuote1: {
    variant: 'customVariants.centerBlock'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuItemName': {
      color: 'text'
    },
    '.cellName': {
      fontFamily: 'body',
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  formContainer: {
    flexDirection: 'column',
    '.button': {
      backgroundColor: 'secondary'
    }
  },

  popup: {
    '.title': {
      fontSize: '1.5rem',
      fontFamily: 'body',
      color: 'white!important',
      fontStyle: 'unset!important'
    },
    '.ctaButton': {
      backgroundColor: 'white!important',
      color: 'black'
    }
  },

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '3.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    flexDirection: ['column', 'column', 'column'],
    '.content_container': {
      padding: '1rem',
      //   width: ['100%', '', '45%', '40%'],
      width: ['100%']
    },
    iframe: {
      width: ['100%', '', '100%', '100%']
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      color: 'text',
      width: '100%',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center',
      borderTop: '1px solid black',
      borderBottom: '1px solid black'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        maxWidth: 600,
        margin: 'auto',
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ===============================
  // ? ====  Private Events page  ====
  // ? ===============================

  privateEvents1: {
    '.text, .title, p, ': {
      color: 'white'
    }
  },
  privateEvents2: {
    '.text, .title, p, ': {
      color: 'tertiary'
    }
  },
  privateEventsForm: {
    variant: 'customVariants.contactForm',

    h2: {
      variant: 'customVariants.title',
      color: 'white',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem', '3rem']
    }
  }
}
